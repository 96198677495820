<template>
  <div class="index">
    <!-- 用户信息 -->
    <div class="basicInfo">
      <p class="title">基础信息</p>
      <div class="item">
        <div class="left">
          <span>用户ID</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.id" size="small" clearable>
          </el-input>
        </div>
        <div class="right">
          <span>电话</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.telephone" size="small" clearable>
          </el-input>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <span>登录名</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.username" size="small" clearable>
          </el-input>
        </div>
        <div class="right">

          <span>性别</span>
          <el-select v-model="userInfo.sex" disabled placeholder="请选择性别" size="small">
            <el-option label="全部" :value="4">
            </el-option>
            <el-option label="男" :value="1">
            </el-option>
            <el-option label="女" :value="2">
            </el-option>
            <el-option label="未填写" :value="3">
            </el-option>
          </el-select>
          <!-- <el-input placeholder="请输入内容" v-model="userInfo.sex" size="small" clearable>
          </el-input> -->
        </div>
      </div>
      <div class="item">
        <div class="left">
          <span>姓名</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.nickname" size="small" clearable>
          </el-input>
        </div>
        <div class="right">
          <span>组织机构</span>
          <el-input placeholder="未选择组织机构" readonly v-model="userInfo.departmentName" size="small" clearable>
          </el-input>
        </div>
        <!-- <div class="right">
          <span>角色</span>
          <el-input placeholder="请输入内容" v-model="value" size="small" clearable>
          </el-input>
        </div> -->
      </div>
      <div class="item">
        <div class="left">
          <span>出生日期</span>
          <el-input placeholder="请输入内容" v-model="userInfo.birthday" size="small" readonly clearable>
          </el-input>
        </div>
        <div class="right">
          <span>禁用</span>
          <el-select disabled v-model="userInfo.status" placeholder="请选择性别" size="small">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
          <!-- <el-input placeholder="请输入内容" v-model="userInfo.status" size="small" clearable>
          </el-input> -->
        </div>
      </div>
      <div class="item">
        <div class="left">
          <span>年龄</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.age" size="small" clearable>
          </el-input>
        </div>
        <div class="right">
          <span>创建日期</span>
          <el-input placeholder="请输入内容" readonly v-model="userInfo.createTime" size="small" clearable>
          </el-input>
        </div>
      </div>
      <!-- <div class="item">
        <div class="left">
          
        </div>
        <div class="right">
          <span>电话</span>
          <el-input placeholder="请输入内容" v-model="value" size="small" clearable>
          </el-input>
        </div>
      </div> -->
    </div>
    <div class="another">
      <p class="title">其他信息</p>
    </div>
  </div>
</template>

<script>
// import { getUserDetail } from '@/api/system/users'
import {getUserInfo} from '@/api/healthRecords/filemanagement'
export default {
  data() {
    return {
      value: null,
      userInfo: {}
    }
  },
  methods: {
    // 获取用户详情
    async getDetail(val) {
      const res = await getUserInfo(val)
      console.log(res);
      if (res.code === 200) {
        this.userInfo = res.data
      }
    },
  },
  created() {
    // console.log(this.$route.query.id)
    this.getDetail(sessionStorage.getItem('fileUserId'));

  }
}
</script>

<style lang="scss" scoped>
.index {
  .title {
    font-weight: 600;
  }

  .basicInfo {
    .item {
      display: flex;
      margin-top: 10px;
      box-sizing: border-box;

      .left,
      .right {
        width: 30%;
        display: flex;
        align-items: center;
        .el-select{
          width: 100%;
        }

        span {
          display: inline-block;
          width: 80px;
          text-align: right;
          margin-right: 10px;
        }
      }

      .right {
        margin-left: 150px;
      }
    }
  }
}
</style>